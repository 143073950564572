import { LatLng } from "use-places-autocomplete"
import { z } from "zod"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

import { addressFormSchema } from "@/modules/publish-flow/validations/publish-flow"

const useStore = create<{
  latLng: LatLng | null
  setLatLng: (latLng: LatLng | null) => void
  addressFormDefaultValues: z.infer<typeof addressFormSchema> | null
  setAddressFormDefaultValues: (
    addressFormDefaultValues: z.infer<typeof addressFormSchema> | null
  ) => void
  clearPublishState: () => void
}>()(
  devtools((set) => ({
    latLng: null,
    setLatLng: (latLng: LatLng | null) => set({ latLng }),
    addressFormDefaultValues: null,
    setAddressFormDefaultValues: (
      addressFormDefaultValues: z.infer<typeof addressFormSchema> | null
    ) => set({ addressFormDefaultValues }),
    clearPublishState: () =>
      set({ latLng: null, addressFormDefaultValues: null }),
  }))
)

export { useStore }
