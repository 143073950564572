import { MainNavItem } from "@/types"

export const siteConfig = {
  name: "wasii.pe",
  mainNav: {
    userGroup: [
      {
        title: "Tus anuncios",
        href: "/usuario/tus-anuncios",
        icon: "building2",
      },
      {
        title: "Tus favoritos",
        href: "/usuario/tus-favoritos",
        icon: "heart",
      },
    ] satisfies MainNavItem[],
    settingsGroup: [
      {
        title: "Cuenta",
        href: "/opciones/cuenta",
        icon: "user",
      },
      {
        title: "Contacto",
        href: "/opciones/contacto",
        icon: "smartphoneNfc",
      },
      {
        title: "Notificaciones",
        href: "/opciones/notificaciones",
        icon: "bellRing",
      },
    ] satisfies MainNavItem[],
  },
}
