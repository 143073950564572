"use client"

import React from "react"
import { useClerk } from "@clerk/nextjs"
import { useRouter } from "next-nprogress-bar"

import { cn } from "@/lib/utils"
import { useMounted } from "@/hooks/use-mounted"
import { Button, buttonVariants } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import { Icons } from "@/components/icons"

export function LogoutButton() {
  const [isPending, startTransition] = React.useTransition()
  const { signOut } = useClerk()
  const router = useRouter()
  const mounted = useMounted()

  return mounted ? (
    <Button
      variant="ghost"
      aria-label="Log out"
      size="sm"
      className="w-full justify-start px-0"
      disabled={isPending}
      onClick={() => {
        startTransition(() => {
          signOut(() => {
            router.push("/")
          })
        })
      }}
    >
      {isPending ? (
        <Icons.spinner className="mr-2 size-4 animate-spin" />
      ) : (
        <Icons.logout className="mr-2 size-4" />
      )}
      Cerrar sesión
    </Button>
  ) : (
    <Skeleton
      className={cn(
        buttonVariants({ size: "sm" }),
        "w-full justify-start bg-muted text-muted-foreground"
      )}
    >
      <Icons.logout className="mr-2 size-4" />
      Cerrar sesión
    </Skeleton>
  )
}
