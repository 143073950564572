"use client"

import { useOrganizationList } from "@clerk/nextjs"

export function useIsWasiiAdmin() {
  const { userMemberships } = useOrganizationList({ userMemberships: true })

  const wasiiAdminMembership = userMemberships?.data?.find(
    (ms) =>
      ms.organization.id === process.env.NEXT_PUBLIC_CLERK_WASII_ORG_ID &&
      ms.role === "admin"
  )
  return { isWasiiAdmin: !!wasiiAdminMembership }
}
