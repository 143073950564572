"use client"

import React from "react"
// import Image from "next/image"
import { Modal, useModalContext } from "@/context/modal-context"
import { useStore } from "@/store/store"
import { useOrganization, useUser } from "@clerk/nextjs"
import { useRouter } from "next-nprogress-bar"
import { usePostHog } from "posthog-js/react"

import { catchError } from "@/lib/utils"
import { Button, ButtonProps } from "@/components/ui/button"
import { Icons } from "@/components/icons"
import { createNewPropertyAction } from "@/app/_actions/properties"

type NewPropertyButtonProps = {
  text: string
  customEventName: string
  variant?: ButtonProps["variant"]
  className?: string
}

function NewPropertyButton({
  text,
  variant,
  customEventName,
  className,
}: NewPropertyButtonProps) {
  const router = useRouter()
  const [isPending, startTransition] = React.useTransition()
  const posthog = usePostHog()
  const { isSignedIn } = useUser()
  const { clearPublishState } = useStore((state) => ({
    clearPublishState: state.clearPublishState,
  }))
  const { openModal } = useModalContext()
  const { organization } = useOrganization()

  const tryCreateNewProperty = () => {
    clearPublishState()
    startTransition(async () => {
      try {
        posthog?.capture(customEventName)
        if (!isSignedIn) {
          router.push("/membresias")
          return
        }
        const propertyId = await createNewPropertyAction(organization?.id)

        if (propertyId) {
          router.push(`/publicar-anuncio/${propertyId}/operacion-y-propiedad`)
        } else {
          openModal(Modal.MembershipPlans)
        }
      } catch (error) {
        catchError(error, {
          showToast: false,
        })
      }
    })
  }

  return (
    <Button
      size="lg"
      variant={variant}
      onClick={tryCreateNewProperty}
      disabled={isPending}
      className={className}
    >
      {isPending && (
        <Icons.spinner
          className="mr-2 size-4 animate-spin"
          aria-hidden="true"
        />
      )}
      {text}
      {/* TODO: Improve design + Test vertical logos */}
      {/* {organization ? (
        <div className="relative ml-4 h-8 w-16">
          <Image
            src={organization.imageUrl}
            alt={organization.name}
            fill
            className="object-contain"
          />
        </div>
      ) : null} */}
    </Button>
  )
}

export { NewPropertyButton }
