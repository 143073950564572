"use client"

import React from "react"
import Image from "next/image"
import Link from "next/link"
import { SignInButton, useAuth, useUser } from "@clerk/nextjs"
import { ChevronLeftIcon } from "lucide-react"

import { siteConfig } from "@/config/site"
import { PosthogEvents } from "@/lib/posthog"
import { cn } from "@/lib/utils"
import { useIsWasiiAdmin } from "@/hooks/use-is-wasii-admin"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icons } from "@/components/icons"
import { LogoutButton } from "@/modules/auth/components/logout-button"

import { NewPropertyButton } from "../new-property-button"

type MainNavProps = {
  isLanding?: boolean
  classNameContainer?: string
  showNewPropertyButton?: boolean
}

export function MainNav({
  isLanding,
  classNameContainer,
  showNewPropertyButton = false,
}: MainNavProps) {
  const { user, isLoaded, isSignedIn } = useUser()
  const { has } = useAuth()
  const { isWasiiAdmin } = useIsWasiiAdmin()

  const email = user?.emailAddresses.find(
    (e) => e.id === user.primaryEmailAddressId
  )?.emailAddress

  const initials = React.useMemo(
    () =>
      `${user?.firstName?.charAt(0) ?? ""} ${user?.lastName?.charAt(0) ?? ""}`,
    [user?.firstName, user?.lastName]
  )

  return (
    <header className="sticky top-0 z-50 block w-full border-b bg-background">
      <div
        className={cn(
          "container flex h-16 items-center lg:h-[72px]",
          isLanding && "max-w-6xl",
          classNameContainer
        )}
      >
        <div className="flex items-center gap-6">
          <Link href="/" className="flex items-center space-x-2">
            <Image
              src="/images/wasii-sticker.png"
              alt="wasii.pe logo sticker"
              width={0}
              height={0}
              priority
              className="h-auto w-32"
              sizes="128px"
            />
            <span className="sr-only">Inicio</span>
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-end space-x-4">
          <nav className="flex items-center space-x-2 sm:space-x-4">
            {showNewPropertyButton && (
              <NewPropertyButton
                text="Publicar Anuncio"
                variant="secondary"
                customEventName={
                  PosthogEvents.LANDING_PAGE_NEW_PROPERTY_BUTTON_CLICK
                }
                className={cn(
                  isSignedIn ? "sm:mr-10" : "sm:mr-0",
                  "w-42 ml-2 h-9 border border-black !px-2 text-xs shadow-md sm:h-10 sm:w-52"
                )}
              />
            )}
            {isSignedIn ? (
              <>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="secondary"
                      className="relative size-8 rounded-full"
                    >
                      <Avatar className="size-8">
                        <AvatarImage
                          src={user?.imageUrl}
                          alt={user?.username ?? ""}
                        />
                        <AvatarFallback>{initials}</AvatarFallback>
                      </Avatar>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-80" align="end" forceMount>
                    <DropdownMenuLabel className="font-normal">
                      <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                          {user.firstName} {user.lastName}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground">
                          {email}
                        </p>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      {siteConfig.mainNav?.userGroup.map((item) => {
                        const Icon = item.icon
                          ? Icons[item.icon]
                          : ChevronLeftIcon
                        return (
                          <DropdownMenuItem asChild key={item.title}>
                            <Link
                              href={String(item.href)}
                              className="h-10 font-medium"
                            >
                              <Icon
                                className="mr-2 size-4"
                                aria-hidden="true"
                              />
                              {item.title}
                            </Link>
                          </DropdownMenuItem>
                        )
                      })}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      {siteConfig.mainNav?.settingsGroup.map((item) => {
                        const Icon = item.icon
                          ? Icons[item.icon]
                          : ChevronLeftIcon
                        return (
                          <DropdownMenuItem asChild key={item.title}>
                            <Link href={String(item.href)} className="h-10">
                              <Icon
                                className="mr-2 size-4"
                                aria-hidden="true"
                              />
                              {item.title}
                            </Link>
                          </DropdownMenuItem>
                        )
                      })}
                      {user.publicMetadata.membershipPlan ===
                        "professional" && (
                        <DropdownMenuItem asChild>
                          <Link
                            href={`/profesional/${user.id}`}
                            className="h-10"
                            target="_blank"
                          >
                            <Icons.enterprise
                              className="mr-2 size-4"
                              aria-hidden="true"
                            />
                            Perfil profesional
                            <Icons.externalLink
                              className="ml-2 size-4"
                              aria-hidden="true"
                            />
                          </Link>
                        </DropdownMenuItem>
                      )}
                      {/* TODO: When we have some time improve the config to render according to roles, organization... */}
                      {has && has({ role: "basic_member" }) && (
                        <DropdownMenuItem asChild key="Organización">
                          <Link href="/opciones/organizacion" className="h-10">
                            <Icons.building2
                              className="mr-2 size-4"
                              aria-hidden="true"
                            />
                            Organización
                          </Link>
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuGroup>
                    {isWasiiAdmin ? (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                          <DropdownMenuLabel>Admin</DropdownMenuLabel>
                          <DropdownMenuItem asChild>
                            <Link href="/backoffice" className="h-10">
                              <Icons.userRoundCog
                                className="mr-2 size-4"
                                aria-hidden="true"
                              />
                              Backoffice
                            </Link>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </>
                    ) : null}
                    {has && has({ role: "admin" }) ? (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                          <DropdownMenuLabel>Administrador</DropdownMenuLabel>
                          <DropdownMenuItem asChild>
                            <Link href="/admin/organizacion" className="h-10">
                              <Icons.building2
                                className="mr-2 size-4"
                                aria-hidden="true"
                              />
                              Organización
                            </Link>
                          </DropdownMenuItem>
                          <DropdownMenuItem asChild>
                            <Link
                              href="/admin/agentes/invitaciones"
                              className="h-10"
                            >
                              <Icons.usersRound
                                className="mr-2 size-4"
                                aria-hidden="true"
                              />
                              Agentes
                            </Link>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </>
                    ) : null}
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem>
                        <LogoutButton />
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </>
            ) : isLoaded ? (
              <>
                <SignInButton>
                  <Button
                    variant="outline"
                    className="h-9 w-24 text-xs shadow-md sm:h-10 sm:w-52"
                  >
                    Ingresar
                  </Button>
                </SignInButton>
              </>
            ) : null}
          </nav>
        </div>
      </div>
    </header>
  )
}
