"use client"

import React from "react"
import {
  usePathname,
  useSearchParams,
  useSelectedLayoutSegments,
} from "next/navigation"
import {
  ExtrasFeaturesEnum,
  PropertyTypeEnum,
  TransactionTypeEnum,
} from "@/db/schemas/enums"
import { SearchParamsKeyEnum } from "@/types"
import { useRouter } from "next-nprogress-bar"

import { SearchConfig } from "@/config/search-config"
import { searchSearchParamsSchema } from "@/lib/validations/params"
import { CheckboxGroupPicker } from "@/modules/search/components/checkbox-group-picker"
import {
  ExtraItem,
  ExtrasPicker,
} from "@/modules/search/components/extras-picker"
import { FilterMultiSelect } from "@/modules/search/components/filter-multi-select"
import { FilterSelect } from "@/modules/search/components/filter-select"
import {
  IncrementalItem,
  IncrementalSelect,
} from "@/modules/search/components/incremental-select"
import { PropertyTypeSelect } from "@/modules/search/components/property-type-select"
import { SearchableRange } from "@/modules/search/components/searchable-range/searchable-range"
import { TransactionTypeTabs } from "@/modules/search/components/transaction-type-tabs"

export function SidebarSearch() {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const validatedSearchParams = searchSearchParamsSchema.parse(
    Object.fromEntries(searchParams)
  )

  /*
   * We are sure that the first segment is the transaction type
   * and the second is the property type
   * because they are validated in the page component
   */
  const transactionType = pathname.split("/")[1] as TransactionTypeEnum
  const propertyType = pathname.split("/")[2] as PropertyTypeEnum

  // Create query string
  const createQueryString = React.useCallback(
    (params: Record<string, string | number | null>) => {
      const newSearchParams = new URLSearchParams(searchParams?.toString())

      for (const [key, value] of Object.entries(params)) {
        if (value === null) {
          newSearchParams.delete(key)
        } else {
          newSearchParams.set(key, String(value))
        }
      }
      newSearchParams.delete(SearchParamsKeyEnum.enum.page)
      newSearchParams.sort()
      return newSearchParams.toString()
    },
    [searchParams]
  )

  const segments = useSelectedLayoutSegments()
  const lastSegment = segments[segments.length - 1]
  if (
    segments.length < 3 ||
    segments.includes("(root)") ||
    lastSegment === "distritos"
  )
    return null

  const handleTransactionTypeChange = (value: string) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(SearchParamsKeyEnum.enum.page)
    const newPath = pathname.replace(`/${transactionType}`, `/${value}`)
    // + `?${newSearchParams.toString()}`
    router.push(newPath, {
      scroll: false,
    })
  }

  const handlePropertyTypeChange = (value: PropertyTypeEnum) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(SearchParamsKeyEnum.enum.page)
    const newPath =
      value === "habitacion"
        ? pathname
            .replace(`/${propertyType}`, `/${value}`)
            .replace(
              `/${transactionType}`,
              `/${TransactionTypeEnum.Values.alquiler}`
            )
        : pathname.replace(`/${propertyType}`, `/${value}`)
    // + `?${newSearchParams.toString()}`
    router.push(newPath, {
      scroll: false,
    })
  }

  const handleCheckboxGroupPickerChange = (
    checkedItems: string[],
    searchParamKey: SearchParamsKeyEnum
  ) => {
    const newPath = `${pathname}?${createQueryString({
      [searchParamKey]:
        checkedItems && checkedItems.length > 0 ? checkedItems.join(",") : null,
    })}`
    router.push(newPath, {
      scroll: false,
    })
  }

  const handleIncrementalSelectChange = (items: IncrementalItem[]) => {
    const keysToAdd = items
      .map((item) => (item.value ? item.key : null))
      .filter(Boolean)
    const keysToRemove = items
      .map((item) => (!item.value ? item.key : null))
      .filter(Boolean)
    const featureKeysParams = validatedSearchParams.caracteristicas
    const featureKeys = featureKeysParams?.split(",")
    const sanitizedKeys =
      featureKeys?.filter((key) => !keysToRemove.includes(key as string)) ?? []
    router.push(
      `${pathname}?${createQueryString({
        [SearchParamsKeyEnum.enum.features]:
          keysToAdd.length > 0
            ? [...new Set([...sanitizedKeys, ...keysToAdd])].join(",")
            : sanitizedKeys.length > 0
              ? sanitizedKeys.join(",")
              : null,
      })}`,
      {
        scroll: false,
      }
    )
  }

  const handleFilterSelectChange = (
    keys: (string | null)[],
    searchParamKey:
      | "estado"
      | "ubicacion_comercial"
      | "actividad_comercial"
      | "fumadores"
      | "mascotas"
      // | "tipo_de_cama"
      | "habitacion_amoblada"
      | "habitacion_bano"
      | "disponible_a_partir_de"
      | "parejas"
      | "menores"
      | "genero_deseado"
  ) => {
    const notNullKeys = keys.filter((s) => s !== null)
    const newSearchParams = createQueryString({
      [searchParamKey]: notNullKeys.length > 0 ? notNullKeys.join(",") : null,
    })
    router.push(`${pathname}?${newSearchParams}`, {
      scroll: false,
    })
  }

  const handleExtrasPickerChange = (items: ExtraItem[]) => {
    const keysToAdd = items
      .map((item) => (item.value ? item.key : null))
      .filter(Boolean)
    const keysToRemove = items
      .map((item) => (!item.value ? item.key : null))
      .filter(Boolean)
    const extrasKeysParams = validatedSearchParams.extras
    const extrasKeys = extrasKeysParams?.split(",")
    const sanitizedKeys =
      extrasKeys?.filter(
        (key) => !keysToRemove.includes(key as ExtrasFeaturesEnum)
      ) ?? []
    router.push(
      `${pathname}?${createQueryString({
        [SearchParamsKeyEnum.enum.extras]:
          keysToAdd.length > 0
            ? [...new Set([...sanitizedKeys, ...keysToAdd])].join(",")
            : sanitizedKeys.length > 0
              ? sanitizedKeys.join(",")
              : null,
      })}`,
      {
        scroll: false,
      }
    )
  }

  return (
    <div className="flex w-full flex-col gap-6 px-3 pb-20 pt-8">
      {transactionType && (
        <TransactionTypeTabs
          value={transactionType}
          onValueChange={handleTransactionTypeChange}
          propertyType={propertyType}
        />
      )}
      {propertyType && (
        <PropertyTypeSelect
          value={propertyType}
          onValueChange={handlePropertyTypeChange}
        />
      )}
      <SearchableRange
        searchParams={validatedSearchParams}
        transactionType={transactionType}
        type="price"
        onValueChange={(params) =>
          router.push(`${pathname}?${createQueryString(params)}`, {
            scroll: false,
          })
        }
      />

      <SearchableRange
        searchParams={validatedSearchParams}
        transactionType={transactionType}
        type="area"
        onValueChange={(params) =>
          router.push(`${pathname}?${createQueryString(params)}`, {
            scroll: false,
          })
        }
        propertyType={propertyType}
      />
      {propertyType === "habitacion" && (
        <>
          <FilterSelect
            label={"Tú eres"}
            configItems={SearchConfig.genderDesiredConfig}
            filterType="desiredGender"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Fumadores"
            configItems={SearchConfig.smokersConfig}
            filterType="smokers"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Mascotas"
            configItems={SearchConfig.petsConfig}
            filterType="pets"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Amoblada"
            configItems={SearchConfig.bedroomIsFurnishedConfig}
            filterType="bedroomIsFurnished"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Baño"
            configItems={SearchConfig.bedroomBathroomConfig}
            filterType="bedroomBathroom"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Parejas"
            configItems={SearchConfig.couplesConfig}
            filterType="couples"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <FilterSelect
            label="Menores de edad"
            configItems={SearchConfig.minorsConfig}
            filterType="minors"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          {/* <FilterSelect
            label="Disponible en"
            configItems={SearchConfig.availableFromConfig}
            filterType="availableFrom"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          /> */}
        </>
      )}
      {propertyType === "departamento" && (
        <CheckboxGroupPicker
          searchParams={validatedSearchParams}
          filterType="apartmentType"
          onChange={handleCheckboxGroupPickerChange}
        />
      )}
      {(propertyType === "casa" || propertyType === "departamento") && (
        <IncrementalSelect
          searchParams={validatedSearchParams}
          label="Dormitorios"
          configItems={SearchConfig.bedroomsConfig}
          onChange={handleIncrementalSelectChange}
        />
      )}
      {propertyType === PropertyTypeEnum.Values.oficina && (
        <IncrementalSelect
          searchParams={validatedSearchParams}
          label="Nº de Plantas"
          configItems={SearchConfig.totalFloorsConfig}
          onChange={handleIncrementalSelectChange}
        />
      )}
      {propertyType === PropertyTypeEnum.Values["local-comercial"] && (
        <>
          <FilterSelect
            label="Estado"
            configItems={SearchConfig.conditionConfig}
            filterType="condition"
            searchParams={validatedSearchParams}
            onChange={(value, searchParamKey) =>
              handleFilterSelectChange([value], searchParamKey)
            }
          />
          <IncrementalSelect
            searchParams={validatedSearchParams}
            label="Ambientes"
            configItems={SearchConfig.roomsConfig}
            onChange={handleIncrementalSelectChange}
          />
        </>
      )}
      {(propertyType === "casa" ||
        propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values["local-comercial"] ||
        propertyType === PropertyTypeEnum.Values.oficina) && (
        <IncrementalSelect
          searchParams={validatedSearchParams}
          label="Baños"
          configItems={SearchConfig.bathroomsConfig}
          onChange={handleIncrementalSelectChange}
        />
      )}
      {(propertyType === "casa" ||
        propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values["local-comercial"] ||
        propertyType === PropertyTypeEnum.Values.oficina) && (
        <IncrementalSelect
          searchParams={validatedSearchParams}
          label="Aparcamientos"
          configItems={SearchConfig.parkingsConfig}
          onChange={handleIncrementalSelectChange}
        />
      )}
      {(propertyType === "casa" ||
        propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values["local-comercial"] ||
        propertyType === PropertyTypeEnum.Values.oficina ||
        propertyType === "habitacion") && (
        <ExtrasPicker
          searchParams={validatedSearchParams}
          transactionType={transactionType}
          propertyType={propertyType}
          onChange={handleExtrasPickerChange}
        />
      )}
      {propertyType === "terreno" && (
        <CheckboxGroupPicker
          searchParams={validatedSearchParams}
          filterType="terrainType"
          onChange={handleCheckboxGroupPickerChange}
        />
      )}
      {propertyType === PropertyTypeEnum.Values["local-comercial"] ||
        (propertyType === PropertyTypeEnum.Values.oficina && (
          <FilterMultiSelect
            label="Ubicación Comercial"
            configItems={SearchConfig.commercialLocationConfig}
            filterType="commercialLocation"
            searchParams={validatedSearchParams}
            searchable={false}
            onChange={handleFilterSelectChange}
          />
        ))}
      {propertyType === PropertyTypeEnum.Values["local-comercial"] && (
        <FilterMultiSelect
          label="Última Actividad Comercial"
          configItems={SearchConfig.commercialActivityConfig}
          filterType="commercialActivity"
          searchParams={validatedSearchParams}
          searchable={true}
          onChange={handleFilterSelectChange}
        />
      )}
      {(propertyType === "casa" ||
        propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values["local-comercial"] ||
        propertyType === PropertyTypeEnum.Values.oficina) &&
        transactionType === "alquiler" && (
          <CheckboxGroupPicker
            searchParams={validatedSearchParams}
            filterType="deposit"
            onChange={handleCheckboxGroupPickerChange}
          />
        )}
      {(propertyType === "casa" ||
        propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values.oficina) && (
        <CheckboxGroupPicker
          searchParams={validatedSearchParams}
          filterType="furnished"
          onChange={handleCheckboxGroupPickerChange}
          propertyType={propertyType}
        />
      )}
      {(propertyType === "departamento" ||
        propertyType === PropertyTypeEnum.Values.oficina) && (
        <CheckboxGroupPicker
          searchParams={validatedSearchParams}
          filterType="views"
          onChange={handleCheckboxGroupPickerChange}
        />
      )}
    </div>
  )
}
